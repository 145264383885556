import React from "react";
import CreateAdminArticles from "../pages/CreateAdminArticles";
import Logo from "./Logo";

const CreateArticle = ({ handleLogout }) => {
  return (
    <>
      <div className="createPage">
        <div className="codeco">
          <Logo />

          <div className="createArticle">
            <h1>Gestion de vos articles !</h1>
            <button onClick={handleLogout}>Deconnexion</button>
          </div>
        </div>
        <CreateAdminArticles />
      </div>
    </>
  );
};

export default CreateArticle;

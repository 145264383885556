import firebase from "firebase";
import "firebase/auth";
import "firebase/storage";

// configuration de la base de donné de google
const firebaseConfig = {
  apiKey: "AIzaSyBZlxc-PGdlBM6bJMDmNaeAzbuNoTnZoBw",
  authDomain: "we-think-greater.firebaseapp.com",
  databaseURL: "https://we-think-greater-default-rtdb.firebaseio.com",
  projectId: "we-think-greater",
  storageBucket: "gs://we-think-greater.appspot.com",
  messagingSenderId: "837338066942",
  appId: "1:837338066942:web:5c330b75f989aa72e75aaf",
  measurementId: "G-0264ZSY4MD",
};
// initialisation de firebase
firebase.initializeApp(firebaseConfig);
// export const timestamp = firebase.fieldValue.serverTimestamp;
export const projectStorage = firebase.storage();
export const fire = firebase;
export const auth = firebase.auth();
export default firebase;

// on appel react
// on appel useState permet de faire des hook
import React, { useState } from "react";

const ContactForm = () => {
  //renseigner dans les input et géré par le useState en utilisant set
  //variable on setNomdelavariable = useState
  //attention les hook sont ascynchrone
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const isEmail = () => {
    let mail = document.getElementById("not-mail");
    //[a-zA-Z0-9._-] toutes les lettres, les chiffres et _- @ idem . l'extension d'adresse mail
    let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // mail macth avec la regex ?
    if (email.match(regex)) {
      mail.style.display = "none";
      // le return permet de valider le if (name && isEmail() && message)
      return true;
    } else {
      mail.style.display = "block";
      //animation de tremblement du texte
      mail.style.animation = "dongle 1s";
      //retire l'animation
      setTimeout(() => {
        mail.style.animation = "none";
      }, 1000);
      return false;
    }
  };

  const failMessage = (message) => {
    let formMess = document.querySelector(".form-message");
    formMess.innerHTML = message;
    formMess.style.opacity = "1";
    formMess.style.color = "red";

    document.getElementById("name").classList.add("error");
    document.getElementById("email").classList.add("error");
    document.getElementById("message").classList.add("error");
  };

  const successMessage = () => {
    let formMess = document.querySelector(".form-message");
    formMess.innerHTML =
      "Message envoyé ! Nous vous recontacterons dès que possible.";
    formMess.style.color = "green";
    formMess.style.opacity = "1";

    document.getElementById("name").classList.remove("error");
    document.getElementById("email").classList.remove("error");
    document.getElementById("message").classList.remove("error");

    setTimeout(() => {
      formMess.style.opacity = "0";
    }, 5000);
  };

  //   fonction handleSubmit pour le submit du form
  const handleSubmit = (e) => {
    e.preventDefault();
    // renseignement que l'on retrouve dans le mail de EmailJS (API)
    // sendFeedback("***TEMPLAYE_ID***" recupère le template id chez EmailJS
    // remplace "***TEMPLAYE_ID***"We-think-greater-2304 par id du template de EmailJS
    if (name && isEmail() && message) {
      sendFeedback("We-think-greater-2304", {
        name,
        company,
        phone,
        email,
        message,
      });
    } else {
      failMessage("Merci de remplir correctement les champs requis *");
    }
  };

  const sendFeedback = (templateId, variables) => {
    window.emailjs
      // reprend le templateId et les variables du sendFeedback dans handleSubmit
      // gmail-EmailJS --> dans emailjs email services
      .send("gmail-EmailJS", templateId, variables)
      // envoie en then pour l'ascynchrone
      .then((res) => {
        //si cela marche on a console log success
        //donc  on state tous à zéro
        successMessage();
        setName("");
        setCompany("");
        setPhone("");
        setEmail("");
        setMessage("");
      })
      //si il y a un problème on catch et message d'erreur
      .catch((err) => {
        failMessage("Une erreur s'est produite, veuillez réessayer.");
      });
  };

  // **************************************** FORMULAIRE ******************************************
  return (
    <form className="contact-form">
      {/* <h2>Contactez-nous</h2> */}
      <div className="form-content">
        <input
          type="text"
          id="name"
          name="name"
          //quand on change on set le name
          onChange={(e) => setName(e.target.value)}
          placeholder="nom *"
          value={name}
          autoComplete="off"
        />
        <input
          type="text"
          id="company"
          name="company"
          onChange={(e) => setCompany(e.target.value)}
          placeholder="société"
          value={company}
        />
        <input
          type="text"
          id="phone"
          name="phone"
          onChange={(e) => setPhone(e.target.value)}
          placeholder="téléphone"
          value={phone}
        />
        <div className="email-content">
          {/* label pour faire apparaitre le message email non valide */}
          <label id="not-mail">Email non valide</label>
          <input
            type="mail"
            id="email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="email *"
            value={email}
            autoComplete="off"
          />
        </div>
        <textarea
          id="message"
          name="message"
          onChange={(e) => setMessage(e.target.value)}
          placeholder="message *"
          value={message}
        />
      </div>
      <input
        className="button"
        type="button"
        value="Envoyer"
        onClick={handleSubmit}
      />
      <div className="form-message"></div>
    </form>
  );
};

export default ContactForm;

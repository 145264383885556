import React, { useState } from "react";
import firebase from "../utils/firebaseConfig";
import { fire } from "../utils/firebaseConfig";

// passé en props item
const UpdateDelete = ({ item }) => {
  //-------------------------------- UPDATE --------------------------------------

  const [update, setUpdate] = useState(false);
  const [titreUpdate, setTitreUpdate] = useState(null);
  const [textUpdate, setTextUpdate] = useState(null);
  const [fileUrlUpdate, setFileUrl] = useState("");
  const [error, setError] = useState(null);
  const types = ["image/png", "image/jpeg", "image/gif"];

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    if (file && types.includes(file.type)) {
      if (file && file.size <= 1000000) {
        console.log(file.size);
        //si target = jpg et autre aok fait le reste sinon message
        //si le poids est sup à 1mo refuse
        const storageRef = fire.storage().ref();
        const fileRef = storageRef.child(file.name);
        await fileRef.put(file);
        setFileUrl(await fileRef.getDownloadURL());

        let article = firebase.database().ref("articlesDB").child(item.id);
        if (fileUrlUpdate !== null) {
          article.update({
            fileUrl: fileUrlUpdate,
          });
        }

        setError("");
      } else {
        setFileUrl(null);
        setError("choisissez une image d'une taille max de 1Mo maximum");
      }
    } else {
      setFileUrl(null);
      setError("Choisissez une image de type png, jpeg ou gif");
    }
  };

  const updateItem = () => {
    let article = firebase.database().ref("articlesDB").child(item.id);

    if (titreUpdate !== null) {
      article.update({
        titre: titreUpdate,
      });
    }
    if (textUpdate !== null) {
      article.update({
        text: textUpdate,
      });
    }
    // if (fileUrlUpdate !== null) {
    //   article.update({
    //     fileUrl: fileUrlUpdate,
    //   });
    // }

    setUpdate(false);
  };
  // --------------------------------- FIN UPDATE ---------------------------------------------
  // ---------------------------------- DELETE ------------------------------------------------
  const deletItem = () => {
    // pour identifier chaque article
    let article = firebase.database().ref("articlesDB").child(item.id);
    // suppression
    article.remove();
  };

  return (
    <>
      <div className="updateDelete">
        {update === false && (
          <>
            <div className="item-container">
              <h3>{item.titre}</h3>
              <p>{item.text}</p>
            </div>
            <div className="buttons-container">
              {/* switch update a true si false et false si true */}
              <button onClick={() => setUpdate(!update)}>Update</button>
              <button onClick={deletItem}>Delete</button>
            </div>
          </>
        )}
        {/* update = true */}
        {update && (
          <div className="item-container-update">
            <input
              type="text"
              defaultValue={item.titre}
              onChange={(e) => setTitreUpdate(e.target.value)}
            />
            <textarea
              defaultValue={item.text}
              onChange={(e) => setTextUpdate(e.target.value)}
            />
            <input className="importFile" type="file" onChange={onFileChange} />
            <div className="output">
              {error && <div className="error">{error}</div>}
            </div>
            <button onClick={updateItem}>Validate Update</button>
          </div>
        )}
      </div>
    </>
  );
};

export default UpdateDelete;

import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "./Logo";

const Navigation = () => {
  return (
    <div className="navContent">
      <Logo />
      {/* </div> */}
      <div className="navbar">
        <ul>
          <li>
            <NavLink exact to="/" activeClassName="navActive">
              <i className="fas fa-home"></i>
              <span>Accueil</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/mentorat" activeClassName="navActive">
              <i className="fas fa-graduation-cap"></i>
              <span>Mentorat</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/culturel" activeClassName="navActive">
              <i className="fas fa-book"></i>
              <span>Culturel</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/catalogue-articles" activeClassName="navActive">
              <i className="fas fa-scroll"></i>
              <span>Artilces</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/contact" activeClassName="navActive">
              <i className="fas fa-map-marked-alt"></i>
              <span>Contact</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navigation;

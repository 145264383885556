import React from "react";
import Navigation from "../components/Navigation";

const Mentorat = () => {
  return (
    <div className="mentorat">
      <Navigation />
      <div className="mentoratContent">
        <div className="blocText">
          <h1>Mentorat</h1>
          <h3>SE CONSTRUIRE UN RÉSEAU PROFESSIONNEL</h3>
          <p>
            La jeune génération est pleine d’idées, de projets et surtout
            d’ambition. Il est donc urgent de l’accompagner. Quoi de mieux que
            d’avoir un <span>Mentor</span> pour éclairer, apporter son expertise
            et ouvrir son réseau d’entraide. Les jeunes (mentorés) vont ainsi
            apprendre à définir leurs projets et fixer des objectifs.
          </p>
          <p>
            Par ailleurs, des ateliers sont proposés aux mentorés afin de
            travailler le développement personnel et l’orientation. Des
            rencontres , salons de l’étudiant ou encore des soirées networking
            sont organisés avec cet objectif de développer son projet et
            maximiser ses chances de réussite.
          </p>
        </div>
        <div className="image">
          <img
            src="./media/img/pages/postit.jpg"
            alt="des postit jaune et rose et des phot sur un mur"
          />
        </div>
      </div>
    </div>
  );
};

export default Mentorat;

import React from "react";
import Navigation from "../components/Navigation";

const Home = () => {
  return (
    <div className="home">
      <Navigation />
      <div className="homeContent">
        <div className="blocText">
          <h1>Think Greater, est...</h1>
          <p>
            Une association qui accompagne les jeunes âgés de 16 à 26 ans dans
            la réalisation de leurs projets qu'ils soient professionnel,
            académique ou encore en vue d'un développement personnel. Cette aide
            se fait par le biais d'un accompagnement réparti sur deux volets:
            <span> Mentorat et Culturel</span>.
          </p>
          <p>
            <span>Notre objectif:</span> permettre à chaque jeune de se
            découvrir, se surpasser, s'estimer pour changer sa manière de penser
            afin d'adopter un nouvel état d'esprit. Tout cela dans le but
            de"Penser grand pour aller plus loin".
          </p>
        </div>
        <div className="image">
          <img
            src="./media/img/pages/echange_mentorat.jpg"
            alt="une jeune fille et sa mentore "
          />
        </div>
      </div>
    </div>
  );
};

export default Home;

// la navigation du site
import React from "react";
// import du router
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// import des lien des pages
import Home from "./pages/Home";
import Article from "./pages/Article";
import CatalogueArticles from "./pages/CatalogueArticles";
import Culturel from "./pages/Culturel";
import Contact from "./pages/Contact";
import Mentorat from "./pages/Mentorat";
import NotFound from "./pages/NotFound";
// import TestFireBase from "./pages/TestFireBase";
import Connect from "./pages/Connect";

const App = () => {
  return (
    <>
      {/* routage vers les différentes pages */}
      <Router>
        {/* switch s'arrête quand on rencontre le chemin */}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/articles" component={Article} />
          <Route
            exact
            path="/catalogue-articles"
            component={CatalogueArticles}
          />
          <Route exact path="/culturel" component={Culturel} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/mentorat" component={Mentorat} />
          {/* <Route path="/firebase" component={TestFireBase} /> */}
          <Route exact path="/connect" component={Connect} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default App;

// tapez rsc => component sans state
import React from "react";
import Navigation from "../components/Navigation";

const Article = () => {
  return (
    <div className="article">
      <Navigation />
      <h3>les Articles sont ici</h3>
    </div>
  );
};

export default Article;

import React, { useEffect, useState } from "react";
//appel de la base de donnée
import firebase from "../utils/firebaseConfig";
import UpdateDelete from "./UpdateDelete";

const ReadCreateArticles = ({ item }) => {
  const [articlesList, setArticlesList] = useState([]);
  useEffect(() => {
    const articlesDB = firebase.database().ref("articlesDB");
    // recup ce qu'il y a dans la base de données
    articlesDB.on("value", (snapshot) => {
      //   console.log(snapshot.val());
      let previousList = snapshot.val();
      let list = [];
      for (let id in previousList) {
        list.push({ id, ...previousList[id] });
      }
      setArticlesList(list);
    });
  }, []);

  return (
    <>
      {articlesList &&
        // affiche le titre et le texte
        articlesList.map((item, index) => {
          // <UpdateDelete item={item} key={index} />
          return (
            <>
              <div className="readCreateArticles">
                <div className="readCreateArticlesContent">
                  <div className="blocText">
                    {/* <h3>{item.titre}</h3>
                    <p>{item.textArticle}</p> */}
                    <UpdateDelete item={item} key={index} />
                  </div>
                  <div className="image">
                    <img src={item.fileUrl}></img>
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </>
  );
};

export default ReadCreateArticles;

import React, { useEffect, useState } from "react";
//appel de la base de donnée
import firebase from "../utils/firebaseConfig";
import UpdateDelete from "./UpdateDelete";

const Read = () => {
  const [articlesList, setArticlesList] = useState([]);
  // usesEffect => remplace le componentDidMount contourne le problème d'effet de bord
  useEffect(() => {
    const articlesDB = firebase.database().ref("articlesDB");
    // recup ce qu'il y a dans la base de données
    //snapshot capture la base de donné à un instant T
    articlesDB.on("value", (snapshot) => {
      //   console.log(snapshot.val());
      let previousList = snapshot.val();
      //list dan slaquelle on met les elements
      let list = [];
      for (let id in previousList) {
        //spread operator recup tout le tabeau et incorpore un id
        list.push({ id, ...previousList[id] });
      }
      setArticlesList(list);
    });
  }, []);

  return (
    <div>
      {/* si articleslist est true */}
      {/* {articlesList &&
          articlesList.map((item, index) => (
            <UpdateDelete item={item} key={index} />
          ))} */}

      {/* s'il y a un article */}
      {/* si la valeur est true */}
      {articlesList &&
        // affiche le titre et le texte
        articlesList.map((item) => {
          console.log();
          return (
            <>
              <div className="readContent">
                <div className="blocText ">
                  <h3>{item.titre}</h3>
                  <p>{item.text}</p>
                </div>
                <div className="image">
                  <img src={item.fileUrl}></img>
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
};

export default Read;

import React, { useState } from "react";
import firebase from "../utils/firebaseConfig";
import { fire } from "../utils/firebaseConfig";

// const db = fire.firestore();

const Create = () => {
  const [titre, setTitre] = useState("");
  const [text, setTextArticle] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  // on spécifie le type d'image dans une constante
  const types = ["image/png", "image/jpeg", "image/gif"];

  // pour les erreurs
  const [error, setError] = useState(null);

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    if (file && types.includes(file.type)) {
      if (file && file.size <= 1000000) {
        console.log(file.size);
        //si target = jpg et autre aok fait le reste sinon message
        //si le poids est sup à 1mo refuse
        const storageRef = fire.storage().ref();
        const fileRef = storageRef.child(file.name);
        await fileRef.put(file);
        setFileUrl(await fileRef.getDownloadURL());
        setError("");
      } else {
        setFileUrl(null);
        setError("choisissez une image d'une taille max de 1Mo maximum");
      }
    } else {
      setFileUrl(null);
      setError("Choisissez une image de type png, jpeg ou gif");
    }
  };

  //   const [img, setImg] = useState("");

  const createArticles = () => {
    // si on change le .ref on crée une nouvelle table
    const articlesDB = firebase.database().ref("articlesDB");
    //stock les variables = titre:titre

    const article = {
      titre,
      text,
      fileUrl,
    };
    articlesDB.push(article);
    // réinitialise à 0 titre text img
    setTitre("");
    setTextArticle("");
    setFileUrl("");
  };

  const onSubmit = (e) => {
    // e.preventDefault();
  };

  return (
    <div className="create">
      <h3>Déposer un article</h3>
      <form onSubmit={onSubmit} className="formCreateArticle">
        {/* <input type="hidden" name="urlPhoto" value={fileUrl} /> */}
        <input
          className="createTitreArticle"
          type="text"
          placeholder="Ici le titre de votre article"
          name="articleTitle"
          value={titre}
          //   récup l'evenement et incrémente la value avec setTitre
          onChange={(e) => setTitre(e.target.value)}
        />
        <textarea
          placeholder="Ecrivez votre article ici..."
          value={text}
          // incremente textarticle
          onChange={(e) => setTextArticle(e.target.value)}
        />
        <input className="importFile" type="file" onChange={onFileChange} />
        <div className="output">
          {error && <div className="error">{error}</div>}
        </div>
        <p>
          Importez une images en cliquant sur choisir fichier. Attention les
          format autorisés sont .jpeg,.jpg,.png,.gif
        </p>
        <button onClick={createArticles}>Create</button>
      </form>
    </div>
  );
};

export default Create;

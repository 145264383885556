import React from "react";
import Create from "../components/Create";
import ReadCreateArticles from "../components/ReadCreateArticles";

const CreateAdminArticles = () => {
  return (
    <>
      <div>
        <Create />
        <ReadCreateArticles />
      </div>
    </>
  );
};

export default CreateAdminArticles;

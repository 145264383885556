import React from "react";
import Navigation from "../components/Navigation";

const Culturel = () => {
  return (
    <div className="culturel">
      <Navigation />
      <div className="culturelContent">
        <div className="blocText">
          <h1>Culturel</h1>
          <h3>SE DÉCOUVRIR AUTREMENT</h3>
          <p>
            Pour « Penser Grand », il faut être en mesure de sortir de sa zone
            de confort et explorer le monde ! Pour cela, nous montons des
            projets culturels. A partir des thématiques, les jeunes vont
            travailler sur un projet culturel ayant une finalité: se découvrir
            et s’estimer par le biais de la culture. A la fin, les jeunes
            devront faire une exposition devant un grand public et un voyage
            culturel permettra de clôturer la saison.
          </p>
        </div>
        <div className="image">
          <img
            src="./media/img/pages/culturel.jpg"
            alt="une jeune fille et sa mentore "
          />
        </div>
      </div>
    </div>
  );
};

export default Culturel;

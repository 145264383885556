import React from "react";
import Navigation from "../components/Navigation";
import Read from "../components/Read";

const CatalogueArticles = () => {
  return (
    <div className="catalogue">
      <Navigation />
      <h1>Nos Articles...</h1>
      <Read />
    </div>
  );
};

export default CatalogueArticles;

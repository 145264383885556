import React from "react";
import Logo from "./Logo";
const Login = (props) => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    handleLogin,
    emaildError,
    passwordError,
  } = props;

  return (
    <section className="login">
      <div className="loginLogo">
        <Logo />
      </div>
      <div className="loginForm">
        <label>Adresse mail</label>
        <input
          type="text"
          //   autofocus
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <p className="errorMsg">{emaildError}</p>
        <label>Mot de passe </label>
        <input
          type="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className=""
        />

        <button className="btn-co" onClick={handleLogin}>
          Valider
        </button>
        <p className="errorMsg">{passwordError}</p>
      </div>
    </section>
  );
};

export default Login;

import React from "react";
import ContactForm from "../components/ContactForm";
import Navigation from "../components/Navigation";

const Contact = () => {
  return (
    <div className="contact">
      <Navigation />
      <div className="contactContent ">
        <div className="blocText">
          <h1>Contactez Nous...</h1>
          {/* <h4>27 rue Edmond Michelet, 77000 Melun</h4> */}
          <ContactForm />
        </div>
        <div className="image">
          <img
            src="./media/img/pages/contact.jpg"
            alt="une jeune fille et sa mentore "
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
